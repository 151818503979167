
import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import { config } from "../../utils/config";
import { OidcRoutesContext } from '../../contexts';
import { MenuBar } from "@metaforcelabs/metaforce-core";
import { useFeature } from '../../hooks/useFeature';
import * as Constants from '../../utils/constants';

export default function AdminLayout({ nav, children }) {
    const oidcRoutesContext = useContext(OidcRoutesContext);

    const feature = useFeature();

    const navigationMenu = [
        {
            linkElement: <Link to="/">Home</Link>,
            name: 'Home',
            current: nav === 'dashboard',
            featureEnabled: true
        },
        {
            linkElement: <Link to="/admin/settings">Settings</Link>,
            name: 'Settings',
            current: nav === 'settings',
            featureEnabled: feature.hasFeature(Constants.viewpointConfigurationFeatureCode)
        }
    ];

    return (
        <div className="relative flex flex-col bg-white h-screen">
            <MenuBar
                navigation={navigationMenu}
                customerName={oidcRoutesContext?.customerInfo?.name}
                customerOrgNumber={oidcRoutesContext?.customerInfo?.orgNumber}
                userProfileImage={oidcRoutesContext?.userProfile?.userProfileImage}
                userName={oidcRoutesContext.userProfile.fullName}
                homeButtonLink={config?.centerpointUiBaseUrl}
                helpLink={config.docsUrl}
            />
            <main className="main-container bg-brand-background z-0 focus:outline-none h-full">
                <div className="max-w-screen-3xl h-full mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col pb-10 h-full">
                        <div className="max-w-screen-3xl h-full">
                            {children}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}