import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { downloadDocument } from "../../../api/documentSearch";

export default function DocumentView() {
    const { schema, documentId, connectionId } = useParams();
    const [base64Data, setBase64Data] = useState();
    const [contentType, setContentType] = useState("application/pdf");

    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const res = await downloadDocument(schema, documentId, connectionId);
            setBase64Data(res.item1);
            setContentType(res.item2);
        }, "Failed to load connection data");
    }

    useEffect(() => {
        loadData()
    }, []);

    return !loadAction.isExecuting && (
        <main className="h-full w-full">
            {base64Data && (
                <object
                    type={contentType}
                    className="h-full w-full"
                    data={`data:${contentType};base64,` + base64Data}>

                </object>
            )}
        </main>


    )
}