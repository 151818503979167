

export const adminProductId = '612359ed400e93e57a3e3032';
export const viewpointUsageFeatureCode = 'VUC';
export const viewpointConfigurationFeatureCode = 'VCC'

export const paginationPageSize = 20;
export const paginationMaxPages = 10;

export const editorEnvironments = {
    development: 0,
    test: 1,
    test1: 2,
    test2: 3,
    test3: 4,
    test4: 5,
    test5: 6,
    integrationTest: 7,
    acceptanceTest: 8,
    production: 9,
};

export const editorEnvironmentNames = {
    [editorEnvironments.development]: 'Development',
    [editorEnvironments.test]: "Test",
    [editorEnvironments.test1]: "Test 1",
    [editorEnvironments.test2]: "Test 2",
    [editorEnvironments.test3]: "Test 3",
    [editorEnvironments.test4]: "Test 4",
    [editorEnvironments.test5]: "Test 5",
    [editorEnvironments.integrationTest]: "IntegrationTest",
    [editorEnvironments.acceptanceTest]: "AcceptanceTest",
    [editorEnvironments.production]: "Production",
};