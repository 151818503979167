import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'
import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useClassNames } from "@metaforcelabs/metaforce-core";

export default function Pagination({ pagination, onPaginationChange, disabled }) {
    const { classNames } = useClassNames();
    const [internalPagination, setInternalPagination] = useState(pagination)

    useEffect(() => {
        setPaginationPage(pagination.currentPage);
    }, [pagination.totalItems, pagination.currentPage])


    const setPaginationPage = (newPageIdx) => {
        let currentPage = newPageIdx;
        const { pageSize, maxPages, totalItems } = pagination;
        const totalPages = Math.ceil(totalItems / pageSize);

        if (currentPage < 0) {
            currentPage = 0;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage = currentPage === 0 ? 0 : pagination.pages[0];
        let endPage = currentPage === 0 ? totalPages : pagination.pages[pagination.pages.length - 1];
        if (totalPages <= maxPages) {
            startPage = 0;
            endPage = totalPages;
        } else {
            let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2) - 1;
            let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2);
            if (currentPage <= maxPagesAfterCurrentPage) {
                startPage = 0;
                endPage = maxPages;
            } else if ((currentPage + maxPagesAfterCurrentPage) >= totalPages) {
                startPage = totalPages - maxPages;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrentPage;
                endPage = currentPage + maxPagesAfterCurrentPage;
            }
        }
        if (startPage <= 0) {
            startPage = 0;
            endPage = (totalPages <= maxPages) ? totalPages : maxPages;
        } else if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - maxPages;
        }

        const pages = _.range(startPage, endPage, 1);

        const startIndex = currentPage * pageSize;

        const newPagination = {
            ...pagination,
            currentPage: currentPage,
            pages: pages,
            pageSize: pageSize,
            maxPages: maxPages,
            totalItems: totalItems,
            totalPages: totalPages,
            startIndex: startIndex,
        };
        setInternalPagination(newPagination);
        return newPagination;
    }

    const handlePreviousPageClick = () => {
        const newPagination = setPaginationPage((internalPagination.currentPage - 1));
        onPaginationChange(newPagination);

    }

    const handleNextPageClick = () => {
        const newPagination = setPaginationPage((internalPagination.currentPage + 1));
        onPaginationChange(newPagination);
    }

    const handlePageIndexClick = (pageIndex) => {
        const newPagination = setPaginationPage(pageIndex);
        onPaginationChange(newPagination);
    }

    return (
        <nav className="w-full px-4 flex items-center justify-between sm:px-0">
            <div className="-mt-px w-0 flex-1 flex">
                <button
                    type="button"
                    className="pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-brand-pink disabled:cursor-not-allowed disabled:text-gray-500"
                    disabled={disabled || (internalPagination.currentPage === 0)}
                    onClick={handlePreviousPageClick}
                >
                    <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Previous
                </button>
            </div>
            <div className="hidden md:-mt-px md:flex">
                {internalPagination?.pages && internalPagination.pages.map((pageIndex) => (
                    <button
                        key={pageIndex}
                        type="button"
                        className={classNames(
                            "pt-4 px-4 inline-flex items-center text-sm font-medium disabled:cursor-not-allowed disabled:text-gray-500",
                            (internalPagination.currentPage === pageIndex)
                                ?
                                "text-brand-pink"
                                :
                                "text-gray-500 hover:text-gray-700 "
                        )}
                        disabled={disabled}
                        onClick={() => handlePageIndexClick(pageIndex)}
                    >
                        {pageIndex + 1}
                    </button>
                ))}

            </div>
            <div className="-mt-px w-0 flex-1 flex justify-end">
                <button
                    type="button"
                    className="pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-brand-pink disabled:cursor-not-allowed disabled:text-gray-500"
                    disabled={disabled || (internalPagination.currentPage === internalPagination.pages[internalPagination.pages.length - 1])}
                    onClick={handleNextPageClick}
                >
                    Next
                    <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
            </div>
        </nav>
    )
}
