import _ from 'lodash';

export const useColumnSetup = (onSuccess) => {
    return {
        getColumnDefinitions: (columnSetup) => {
            const columnDefinitions = columnSetup.schemaSetups?.find(x => x.name === columnSetup.schema)?.columnDefinitions;
            const payload = _.cloneDeep(columnDefinitions);
            payload.sort(function (a, b) { return a.order - b.order });
            return payload;
        },
        getViewedColumnDefinitions: (columnSetup) => {
            const columnDefinitions = columnSetup?.schemaSetups?.find(x => x.name === columnSetup.schema)?.columnDefinitions?.filter(x => x.viewInHitlist);
            const payload = _.cloneDeep(columnDefinitions);
            payload.sort(function (a, b) { return a.order - b.order });
            return payload;
        },
        getColumnDefinition: (columnSetup, columnDefinitionId) => {
            return columnSetup.schemaSetups?.find(x => x.name === columnSetup.schema)?.columnDefinitions?.find(y => y.id === columnDefinitionId);
        },
        moveUp: (columnSetup, columnDefinitionId) => {
            const payload = _.cloneDeep(columnSetup);
            const schemaSetupIndex = payload.schemaSetups.findIndex(x => x.name === payload.schema);
            if (schemaSetupIndex === -1) {
                return;
            }
            const columnDefinitionIndex = payload.schemaSetups[schemaSetupIndex].columnDefinitions.findIndex(x => x.id === columnDefinitionId);
            if (columnDefinitionIndex === -1) {
                return;
            }
            if (payload.schemaSetups[schemaSetupIndex].columnDefinitions[columnDefinitionIndex].order === 0) {
                return;
            }

            const previousColumnDefinitionIndex = payload.schemaSetups[schemaSetupIndex].columnDefinitions.findIndex(x => x.order === (payload.schemaSetups[schemaSetupIndex].columnDefinitions[columnDefinitionIndex].order - 1));
            if (previousColumnDefinitionIndex === -1) {
                return;

            }

            payload.schemaSetups[schemaSetupIndex].columnDefinitions[columnDefinitionIndex].order = payload.schemaSetups[schemaSetupIndex].columnDefinitions[columnDefinitionIndex].order - 1;
            payload.schemaSetups[schemaSetupIndex].columnDefinitions[previousColumnDefinitionIndex].order = payload.schemaSetups[schemaSetupIndex].columnDefinitions[previousColumnDefinitionIndex].order + 1;

            onSuccess(payload);
        },
        moveDown: (columnSetup, columnDefinitionId) => {
            const payload = _.cloneDeep(columnSetup);
            const schemaSetupIndex = payload.schemaSetups.findIndex(x => x.name === payload.schema);
            const columnDefinitionIndex = payload.schemaSetups[schemaSetupIndex].columnDefinitions.findIndex(x => x.id === columnDefinitionId);

            if (payload.schemaSetups[schemaSetupIndex].columnDefinitions[columnDefinitionIndex].order === (payload.schemaSetups[schemaSetupIndex].columnDefinitions.length - 1)) {
                return;
            }

            const nexColumnDefinitionIndex = payload.schemaSetups[schemaSetupIndex].columnDefinitions.findIndex(x => x.order === (payload.schemaSetups[schemaSetupIndex].columnDefinitions[columnDefinitionIndex].order + 1));
            if (nexColumnDefinitionIndex === -1) {
                return;
            }

            payload.schemaSetups[schemaSetupIndex].columnDefinitions[columnDefinitionIndex].order = payload.schemaSetups[schemaSetupIndex].columnDefinitions[columnDefinitionIndex].order + 1;
            payload.schemaSetups[schemaSetupIndex].columnDefinitions[nexColumnDefinitionIndex].order = payload.schemaSetups[schemaSetupIndex].columnDefinitions[nexColumnDefinitionIndex].order - 1;

            onSuccess(payload);
        }
    }
}