
import { useParams, useHistory } from "react-router-dom"
import { useState, useEffect } from "react";
import * as yup from "yup";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { FormTextInput } from "../../../../components/Form/FormTextInput";
import { FormCheckbox } from "../../../../components/Form/FormCheckbox";
import { useColumnSetup } from "../../../../hooks/useColumnSetup";
import { updateColumnSetup, getColumnSetupByConnectionId } from "../../../../api/columnSetup";
import _ from 'lodash';
import { useSelector, useDispatch } from "react-redux";
import { setColumnSetup } from "../../../../slices/columnSetupSlice";
import { Formik } from "formik";

export default function EditColumnDefinition() {
    const returnPath = '/admin/settings';
    const history = useHistory();
    const { columnDefinitionId } = useParams();
    const { getColumnDefinition } = useColumnSetup();

    const dispatch = useDispatch();
    const selectedPrivateConnectionId = useSelector((state) => state.columnSetup.selectedPrivateConnectionId);
    const columnSetup = useSelector((state) => state.columnSetup.current);

    const [columnDefinition, setColumnDefinition] = useState(null);

    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const res = await getColumnSetupByConnectionId(selectedPrivateConnectionId);
            dispatch(setColumnSetup({ columnSetup: res }));

            const cd = getColumnDefinition(res, columnDefinitionId);
            setColumnDefinition(cd);
        }, "Failed to load")
    }

    useEffect(() => {
        loadData();
    }, [columnDefinitionId])

    let EditColumnDefinitionSchema = yup.object().shape({
        presentationLabel: yup.string().required("Required"),
    });

    const handleClose = () => {
        history.push(returnPath);
    };

    const handleSubmitForm = async (values) => {
        const newColumnDefinition = {
            ...columnDefinition,
            presentationLabel: values.presentationLabel,
            dataMapping: values.dataMapping,
            system: values.system,
            viewInHitlist: values.viewInHitlist,
            searchColumn: values.searchColumn
        }

        const payload = _.cloneDeep(columnSetup);
        const schemaSetupIndex = payload.schemaSetups.findIndex(x => x.name === payload.schema);
        const columnDefinitionIndex = payload.schemaSetups[schemaSetupIndex].columnDefinitions.findIndex(x => x.id === columnDefinition.id);
        payload.schemaSetups[schemaSetupIndex].columnDefinitions[columnDefinitionIndex] = newColumnDefinition;
        const updateResult = await updateColumnSetup(payload);
        dispatch(setColumnSetup({ columnSetup: updateResult }));
        handleClose();
    }

    return !loadAction.isExecuting && (
        columnDefinition && (
            <div className="lg:max-w-xl mx-auto px-4 py-4">
                <Formik
                    initialValues={{
                        presentationLabel: columnDefinition.presentationLabel,
                        dataMapping: columnDefinition.dataMapping,
                        system: columnDefinition.system,
                        viewInHitlist: columnDefinition.viewInHitlist,
                        searchColumn: columnDefinition.searchColumn,
                    }}
                    validationSchema={EditColumnDefinitionSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        await handleSubmitForm(values);
                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col h-full">
                                <div className="mt-5 pb-4 flex justify-between items-center">
                                    <h1 className="text-3xl font-bold">Edit Column {columnDefinition.viewpointColumn}</h1>
                                </div>
                                <div className="h-full flex flex-col space-y-5 mt-5">
                                    <FormTextInput
                                        name="presentationLabel"
                                        label={"Presentation Label"}
                                        value={values.presentationLabel}
                                        disabled={values.system}
                                        onChange={handleChange}
                                        required={true}
                                        error={errors.presentationLabel}
                                        touched={touched.presentationLabel}
                                    />
                                    <FormTextInput
                                        name="dataMapping"
                                        label={"Data Mapping"}
                                        value={values.dataMapping}
                                        disabled={values.system}
                                        onChange={handleChange}
                                    />
                                    <FormCheckbox
                                        name="system"
                                        label={"System"}
                                        value={values.system}
                                        disabled={true}
                                        onChange={handleChange}
                                    />
                                    <FormCheckbox
                                        name="viewInHitlist"
                                        label={"View In Hitlist"}
                                        value={values.viewInHitlist}
                                        onChange={handleChange}
                                    />
                                    <FormCheckbox
                                        name="searchColumn"
                                        label={"Search Column"}
                                        value={values.searchColumn}
                                        disabled={values.viewpointColumn === "ArchiveDate"}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="flex flex-wrap sm:flex-nowrap flex-col-reverse sm:flex-row mt-3">
                                    <div className="sm:flex-1 sm:pr-2 w-full sm:w-1/2 py-2">
                                        <button
                                            type="button"
                                            disabled={isSubmitting}
                                            className="w-full inline-flex justify-center items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-gray-600 bg-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink mr-4"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </button>

                                    </div>
                                    <div className="sm:flex-1 sm:pl-2 w-full sm:w-1/2 py-2">
                                        <button
                                            type="submit"
                                            className="w-full inline-flex justify-center items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-pink hover:bg-brand-pink-hover disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink"
                                            disabled={isSubmitting}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        )

    )
}