import React from 'react';
import styles from '../DateField.module.scss';
import { useClassNames } from '@metaforcelabs/metaforce-core';
import { Select } from '../../Form/Select';

const DateFieldYearMonthForm = ({ fromMonth, toMonth, date, localeUtils, onChange }) => {
  const months = localeUtils.getMonths();
  const dateMonth = date.getMonth();
  const dateFullYear = date.getFullYear();
  const years = [];

  const { classNames } = useClassNames();

  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChangeYear = (value) => {
    onChange(new Date(parseInt(value), dateMonth));
  };

  const handleChangeMonth = (value) => {
    onChange(new Date(dateFullYear, parseInt(value)));
  };

  return (
    <div className={classNames('DayPicker-Caption', styles.caption)}>
      <div className={styles.captionBody}>
        <Select
          name="month"
          className={classNames(styles.captionElement)}
          selectedValue={dateMonth.toString()}
          options={months.map((month, i) => ({
            name: month,
            value: i.toString()
          }))}
          onChange={handleChangeMonth}
        />

        <Select
          name="year"
          className={styles.captionElement}
          selectedValue={dateFullYear}
          options={years.reverse().map((year) => ({
            name: year,
            value: year
          }))}
          onChange={handleChangeYear}
        />
      </div>
    </div>
  );
};

export default DateFieldYearMonthForm;
