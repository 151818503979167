
import { useContext } from "react"
import { Link } from "react-router-dom";
import { SettingsActionsContext } from "../../../contexts"
import { ArrowNarrowDownIcon } from "@heroicons/react/outline";
import { ArrowNarrowUpIcon } from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/outline";
import { useColumnSetup } from "../../../hooks/useColumnSetup";

import { useSelector } from "react-redux";

export default function ColumnDefinitionTable() {
    const settingsActionsContext = useContext(SettingsActionsContext);

    const columnSetup = useSelector((state) => state.columnSetup.current);
    const { getColumnDefinitions } = useColumnSetup();
    const columnDefinitions = getColumnDefinitions(columnSetup);

    return (
        <>
            <table className="h-full min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Viewpoint Column
                        </th>
                        <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Presentation Label
                        </th>
                        <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Data Mapping
                        </th>
                        <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            System
                        </th>
                        <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            View In Hitlist
                        </th>
                        <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Search Column
                        </th>
                        <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {columnDefinitions && columnDefinitions.map((columnDefinition) => (
                        <tr key={columnDefinition.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 relative">
                                {columnDefinition.viewpointColumn}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{columnDefinition.presentationLabel}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{columnDefinition.dataMapping}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{columnDefinition.system.toString()}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{columnDefinition.viewInHitlist.toString()}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{columnDefinition.searchColumn.toString()}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <div className="flex gap-x-2">
                                    <button
                                        className="-my-2 p-1 bg-white flex items-center hover:text-brand-pink disabled:bg-gray-200 disabled:text-gray-400 focus:outline-none disabled:cursor-not-allowed border border-gray-300 rounded-md"
                                        type="button"
                                        disabled={columnDefinition.order === (columnDefinitions.length - 1)}
                                        onClick={() => settingsActionsContext.actions.moveDown(columnSetup, columnDefinition.id)}
                                    >
                                        <ArrowNarrowDownIcon className="h-5 w-5"></ArrowNarrowDownIcon>
                                    </button>
                                    <button
                                        className="-my-2 p-1 bg-white flex items-center hover:text-brand-pink disabled:bg-gray-200 disabled:text-gray-400 focus:outline-none disabled:cursor-not-allowed border border-gray-300 rounded-md"
                                        type="button"
                                        disabled={columnDefinition.order === 0}
                                        onClick={() => settingsActionsContext.actions.moveUp(columnSetup, columnDefinition.id)}
                                    >
                                        <ArrowNarrowUpIcon className="h-5 w-5"></ArrowNarrowUpIcon>
                                    </button>
                                    <Link
                                        key={columnDefinition.viewpointColumn}
                                        to={`/admin/settings/${columnDefinition.id}/edit`}
                                        className="-my-2 p-1 bg-white flex items-center hover:text-brand-pink disabled:bg-gray-200 disabled:text-gray-400 focus:outline-none disabled:cursor-not-allowed border border-gray-300 rounded-md">
                                        <PencilIcon className="h-5 w-5"></PencilIcon>
                                    </Link>
                                </div>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}
