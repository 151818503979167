import { get, BaseUrl, post } from './api'


export const getColumnSetupByConnectionId = async (viewpointConnectionId) => {
  if (viewpointConnectionId) {
    return await get(`api/ColumnSetup/getOrCreateByConnectionId/${viewpointConnectionId}`, BaseUrl.Api);
  } else {
    return await get(`api/ColumnSetup/getOrCreate`, BaseUrl.Api);
  }
}

export const getColumnSetup = async (id) => {
  return await get(`api/ColumnSetup/${id}`, BaseUrl.Api);
}

export const getColumnSetupHistory = async (id) => {
  return await get(`api/ColumnSetup/history/${id}`, BaseUrl.Api);
}

export const updateColumnSetup = async (columnSetup) => {
  return await post(`api/ColumnSetup/update`, BaseUrl.Api, columnSetup);
}

export const updateColumnSetupSchema = async (columnSetup) => {
  return await post(`api/ColumnSetup/updateSchema`, BaseUrl.Api, columnSetup);
}

