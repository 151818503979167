import moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/da';
import 'moment/locale/sv';
import 'moment/locale/es';
import 'moment/locale/ar';

const DateTimeParser = {
  setStartOfDate(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  },

  setEndOfDate(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
  },

  convertUtcStringToLocaleDateTimeString(utcDateString) {
    const dateObj = new Date(utcDateString);
    return dateObj.toLocaleString(window.navigator.language);
  },

  toLocaleDateString(date) {
    return date.toLocaleDateString(window.navigator.language);
  },
};
export default DateTimeParser;
