import { useClassNames } from '@metaforcelabs/metaforce-core';

export const Select = ({
  options,
  id,
  onChange,
  selectedValue = null,
  label = null,
  disabled = false,
  defaultOptionText = null,
  horizontal = false,
  className,
  required
}) => {
  const { classNames } = useClassNames();
  return (
    <div className={classNames(className, horizontal ? 'h-full w-full flex items-center mt-1' : '')}>
      {label && (
        <label
          htmlFor={id}
          className={classNames(
            horizontal ? 'flex-1' : '',
            'block text-sm font-medium text-gray-700'
          )}
        >
          {label}
          {required === true && <span className="text-red-400"> *</span>}
        </label>
      )}
      <select
        id={id}
        name={id}
        className={classNames(
          horizontal ? 'flex-1' : '',
          label && 'mt-2',
          'block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-pink focus:border-brand-pink sm:text-sm rounded-md'
        )}
        onChange={(evt) => onChange(evt.target.value, id)}
        value={selectedValue}
        disabled={disabled}
      >
        {defaultOptionText && <option value="">{defaultOptionText}</option>}
        {options.map((o) => {
          return (
            <option key={o.value} value={o.value}>
              {o.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
