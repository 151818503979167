import { getColumnSetupByConnectionId, updateColumnSetupSchema } from "../../../api/columnSetup";
import { updateColumnSetup } from "../../../api/columnSetup";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
import { useState, useEffect, useRef, Fragment } from "react";
import { setColumnSetup } from "../../../slices/columnSetupSlice";
import { useColumnSetup } from "../../../hooks/useColumnSetup";
import { SettingsActionsContext } from "../../../contexts";
import ColumnDefinitionTable from "./columnDefinitionTable";
import ColumnSetupHistorySidebar from "./columnSetupHistorySidebar";
import { MenuIcon } from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";

export default function Settings() {
    const columnSetup = useSelector((state) => state.columnSetup.current);
    let selectedPrivateConnectionId = columnSetup?.viewpointConnectionId;
    const dispatch = useDispatch();
    const [historyUpdated, setHistoryUpdated] = useState(false);

    const historyReferenceElement = useRef();
    const [historyPopoverTop, setHistoryPopoverTop] = useState(0);

    const handleHistoryClick = () => {
        const rect = historyReferenceElement.current.getBoundingClientRect();
        setHistoryPopoverTop(rect.bottom);
    }

    const loadAction = useToastAction();
    const saveAction = useToastAction();
    const updateAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const res = await getColumnSetupByConnectionId(selectedPrivateConnectionId);
            selectedPrivateConnectionId = res?.viewpointConnectionId;
            dispatch(setColumnSetup({ columnSetup: res }));
        }, "Failed to load connection data");
    }

    useEffect(() => {
        loadData()
    }, []);

    const handleSelectedPrivateConnectionIdChange = async (id) => {
        selectedPrivateConnectionId = id;
        await loadData();
    }
    const save = async (columnSetup) => {
        saveAction.execute(async () => {
            dispatch(setColumnSetup({ columnSetup: columnSetup }));
            const updateResult = await updateColumnSetup(columnSetup);
            setHistoryUpdated(!historyUpdated);
        }, "Failed to save connection data", "saved");
    }

    const { moveUp, moveDown } = useColumnSetup(save);

    const handleChangeSchema = (schema) => {
        dispatch(setColumnSetup(prevState => ({
            columnSetup: {
                ...prevState,
                schema: schema,
            }
        })));
    }

    const handleUpdateColumns = async () => {
        updateAction.execute(async () => {
            const updateResult = await updateColumnSetupSchema(columnSetup);
            dispatch(setColumnSetup({ columnSetup: updateResult }));
        }, "Failed to update columns");
    }

    return !loadAction.isExecuting && (
        <>
            {columnSetup &&
                <div className="flex-1 flex flex-col w-full h-full gap-4">
                    <Popover className="flex-1 w-full h-full flex flex-col">
                        <div className="mt-5 pb-4 border-b border-gray-200 flex justify-between items-center">
                            <h1 className="text-3xl font-bold">ViewPoint Settings</h1>
                            <div className='flex justify-end mt-5 pb-5'>
                                <div className="">
                                    {columnSetup.allPrivateConnections && columnSetup.allPrivateConnections.length >= 1 && (
                                        <select className='shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-brand-pink focus:border-brand-pink'
                                            value={selectedPrivateConnectionId}
                                            onChange={e => handleSelectedPrivateConnectionIdChange(e.target.value)}
                                        >
                                            {
                                                columnSetup.allPrivateConnections.map(o => <option key={o.id} value={o.id}>{o.customerEnvironmentName}</option>)
                                            }
                                        </select>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 z-999 flex flex-col w-full h-full gap-4">
                            <div className="mt-5 flex gap-x-3">
                                {columnSetup.schemaSetups && columnSetup.schemaSetups.length > 1 && (
                                    <div className="flex items-center ">
                                        <label htmlFor="schema" className="text-sm font-medium text-gray-500 mr-3">Schema</label>
                                        <select
                                            id="schema"
                                            className="focus:ring-brand-pink focus:border-brand-pink block sm:text-sm border-gray-300 rounded-md"
                                            value={columnSetup.schema}
                                            onChange={e => handleChangeSchema(e.target.value)}
                                        >
                                            {columnSetup.schemaSetups.map((schema) => (<option id={schema.name} key={schema.name}>{schema.name}</option>))}
                                        </select>
                                    </div>
                                )}

                                <div className="flex-1">
                                    <button
                                        className="w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
                                        onClick={() => handleUpdateColumns()}
                                        disabled={updateAction.isExecuting}
                                    >
                                        Update Columns
                                    </button>
                                </div>

                                <Popover.Button
                                    ref={historyReferenceElement}
                                    className="justify-end inline-flex items-center focus:outline-none focus:border-brand-pink xl:hidden"
                                    onClick={handleHistoryClick}
                                >
                                    <span className="sr-only">Open history menu</span>
                                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                </Popover.Button>

                            </div>
                            {!updateAction.isExecuting && (
                                <>
                                    <div className="h-full w-full flex flex-row gap-4">
                                        <main className="flex-1 relative z-999 w-9/12  h-full shadow-xl">
                                            <div className="absolute inset-0 shadow-xl h-full">
                                                <div className="sm:min-h-full my-1 print:my-0 print:min-h-screen bg-white overflow-y-auto h-full">
                                                    <SettingsActionsContext.Provider
                                                        value={{
                                                            actions: { moveUp, moveDown },
                                                        }}
                                                    >
                                                        <ColumnDefinitionTable />
                                                    </SettingsActionsContext.Provider>
                                                </div>
                                            </div>
                                        </main>
                                        <aside className="hidden h-full relative xl:flex xl:flex-col overflow-y-auto flex-shrink-0 w-3/12  my-1 shadow-xl bg-white">
                                            <div className="absolute inset-0 h-full ">
                                                <div className="absolute inset-0 h-full ">
                                                    <div className="w-full h-full">
                                                        <ColumnSetupHistorySidebar updated={historyUpdated} />
                                                    </div>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="duration-150 ease-out"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="duration-150 ease-in"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Popover.Panel as="div" className="fixed top-0 right-0 overflow-hidden z-50 px-6 " style={{ top: `${historyPopoverTop}px` }}>
                                            <div className="xl:hidden max-w-full border rounded shadow-lg bg-white">
                                                <div className="overflow-y-auto h-64">
                                                    <ColumnSetupHistorySidebar updated={historyUpdated} />
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </div>
                    </Popover>
                </div>
            }
        </>
    )
}