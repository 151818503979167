
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { getColumnSetupByConnectionId } from "../../../api/columnSetup";
import { searchDocument } from "../../../api/documentSearch";
import { setColumnSetup } from "../../../slices/columnSetupSlice";
import "./styles.scss";
import { FormTextInput } from "../../../components/Form/FormTextInput";
import { FormDate } from "../../../components/Form/FormDate";
import * as yup from "yup";
import DocumentTable from "./documentTable";
import * as Constants from "../../../utils/constants";
import * as _ from "lodash"
import { useColumnSetup } from "../../../hooks/useColumnSetup";
import { Formik } from "formik";
import { ViewpointSearchContext } from "../../../contexts";
import DateField from "../../../components/DateField";
import DateTimeParser from "../../../utils/DateTimeParser";
import { OidcRoutesContext } from "../../../contexts";
import { Select } from "../../../components/Form/Select";

export default function Dashboard() {
    const columnSetup = useSelector((state) => state.columnSetup.current);
    let selectedPrivateConnectionId = columnSetup?.viewpointConnectionId;
    const dispatch = useDispatch();
    const { getViewedColumnDefinitions } = useColumnSetup();
    const { userProfile } = useContext(OidcRoutesContext);

    const now = new Date();
    let day30Before = new Date();
    day30Before.setDate(now.getDate() - 30);
    const initialSearchRequest = {
        columnSetupDto: columnSetup,
        text: '',
        fromDate: DateTimeParser.setStartOfDate(day30Before),
        toDate: DateTimeParser.setEndOfDate(now),
        customerEnvironment: Constants.editorEnvironments.development,
        take: Constants.paginationPageSize,
        skip: 0,
    }

    const [searchRequest, setSearchRequest] = useState(initialSearchRequest);
    const [searchResult, setSearchResult] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const [pagination, setPagination] = useState({
        currentPage: 0,
        pages: [0],
        pageSize: Constants.paginationPageSize,
        maxPages: Constants.paginationMaxPages,
        totalItems: 0,
        totalPages: 0,
        startIndex: 0,
    });

    const loadAction = useToastAction();
    const searchAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const res = await getColumnSetupByConnectionId(selectedPrivateConnectionId);
            const firstColumnDefiniton = getViewedColumnDefinitions(res).find(x => true);
            selectedPrivateConnectionId = res.viewpointConnectionId;
            setSearchRequest(prevState => ({
                ...prevState,
                columnSetupDto: res,
                sorter: {
                    columnDefinitionId: firstColumnDefiniton.id,
                    column: firstColumnDefiniton.viewpointColumn,
                    sortOrder: "asc"
                }
            }));
            setIsLoaded(true);
            dispatch(setColumnSetup({ columnSetup: res }));
        }, "Failed to load ViewPoint connection data from Centerpoint");
    }

    useEffect(() => {
        loadData()
    }, []);

    const handleSelectedPrivateConnectionIdChange = async (id) => {
        selectedPrivateConnectionId = id;
        setSearchRequest(initialSearchRequest);
        setSearchResult(null);
        await loadData();
    }

    const handleDateChange = ({ name, value }) => {
        const date = new Date(value);
        if (date) {
            setSearchRequest(prevState => ({
                ...prevState,
                [name]: date
            }));
        }
    };

    const handlePaginationChange = async (newPagination) => {
        setPagination(newPagination);
        const newSearchRequest = _.cloneDeep(searchRequest);
        newSearchRequest.skip = newPagination.startIndex;
        setSearchRequest(newSearchRequest);

        await handleSearch(newSearchRequest, newPagination);
    }

    const handleTableSort = async (sorter) => {
        const newSearchRequest = _.cloneDeep(searchRequest);
        newSearchRequest.sorter = sorter;
        newSearchRequest.skip = 0;
        setSearchRequest(newSearchRequest);

        await handleSearch(newSearchRequest);
    }

    const handleSearch = async (newSearchRequest, newPagination) => {
        searchAction.execute(async () => {
            const request = _.cloneDeep(newSearchRequest);

            const res = await searchDocument(request);
            setSearchResult(res);

            const clonedPagination = _.cloneDeep(newPagination ? newPagination : pagination);
            setPagination({
                ...clonedPagination,
                totalItems: res.count,
                totalPages: Math.ceil(res.count / clonedPagination.pageSize),
                currentPage: newSearchRequest.skip === 0 ? 0 : clonedPagination.currentPage,
            })
        }, "Failed to search documents");
    }

    let SearchSchema = yup.object().shape({
        fromDate: yup.date().required("Required"),
        toDate: yup.date().required("Required").test(
            'distributionTest',
            null,
            (obj, e) => {
                if (e.parent.fromDate <= e.parent.toDate) {
                    return true;
                }
                return new yup.ValidationError(
                    'To date must be greater than from date.',
                    null,
                    'toDate'
                );
            }),
    });

    const handleSubmitForm = async (values) => {
        const newSearchRequest = {
            ...searchRequest,
            text: values.text,
            skip: 0
        };
        setSearchRequest(newSearchRequest);

        await handleSearch(newSearchRequest);
    }

    const handleKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return !loadAction.isExecuting && (
        <>
            {columnSetup && (
                <ViewpointSearchContext.Provider value={{
                    searchRequest: searchRequest,
                    searchResult: searchResult,
                    isSearching: searchAction.isExecuting,
                    pagination: pagination,
                    onTableSort: (sorter) => {
                        handleTableSort(sorter);
                    },
                    onPaginationChange: (updatedPagination) => {
                        handlePaginationChange(updatedPagination);
                    }
                }}>
                    <div className="flex-1 flex flex-col w-full h-full">
                        <div className="mt-5 pb-4 border-b border-gray-200 flex justify-between items-center">
                            <h1 className="text-3xl font-bold">ViewPoint Search</h1>
                            <div className='flex justify-end mt-5 pb-5'>
                                <div className="">
                                    {columnSetup.allPrivateConnections && columnSetup.allPrivateConnections.length >= 1 && (
                                        <select className='shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-brand-pink focus:border-brand-pink'
                                            value={selectedPrivateConnectionId}
                                            onChange={e => handleSelectedPrivateConnectionIdChange(e.target.value)}
                                        >
                                            {
                                                columnSetup.allPrivateConnections.map(o => <option key={o.id} value={o.id}>{o.customerEnvironmentName}</option>)
                                            }
                                        </select>
                                    )}
                                </div>
                            </div>
                        </div>


                        <div className="flex-1 flex flex-col h-full w-full">
                            <div className="py-3 w-full border-b border-gray-200 flex flex-col justify-between items-center">
                                <Formik
                                    initialValues={{
                                        text: searchRequest.text,
                                        fromDate: searchRequest.fromDate,
                                        toDate: searchRequest.toDate,
                                    }}
                                    validationSchema={SearchSchema}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        await handleSubmitForm(values);
                                        setSubmitting(false);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        setFieldValue,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        /* and other goodies */
                                    }) => (
                                        <form className="w-full h-full" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                                            <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-4 lg:grid-cols-6">
                                                <div className="col-span-1 sm:col-span-2">
                                                    <FormTextInput
                                                        name="text"
                                                        label={"Search"}
                                                        value={values.text}
                                                        disabled={searchAction.isExecuting}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-span-1 sm:col-span-1 mb-6 sm:mb-auto">
                                                    <DateField
                                                        navbar={true}
                                                        name="fromDate"
                                                        label={"Date from"}
                                                        value={values.fromDate}
                                                        maxDate={values.toDate}
                                                        cultureCode={userProfile.languageCode}
                                                        disabled={searchAction.isExecuting}
                                                        onChange={(v, e) => {
                                                            setFieldValue('fromDate', DateTimeParser.setStartOfDate(v));
                                                            setSearchRequest(prevState => ({
                                                                ...prevState,
                                                                fromDate: DateTimeParser.setStartOfDate(v)
                                                            }));
                                                        }}
                                                        error={errors.fromDate}
                                                    />
                                                </div>
                                                <div className="col-span-1 sm:col-span-1 mb-6 sm:mb-auto">
                                                    <DateField
                                                        navbar={true}
                                                        name="toDate"
                                                        label={"Date to"}
                                                        value={values.toDate}
                                                        cultureCode={userProfile.languageCode}
                                                        disabled={searchAction.isExecuting}
                                                        onChange={(v, e) => {
                                                            setFieldValue('toDate', DateTimeParser.setEndOfDate(v));
                                                            setSearchRequest(prevState => ({
                                                                ...prevState,
                                                                toDate: DateTimeParser.setEndOfDate(v)
                                                            }));
                                                        }}
                                                        error={errors.toDate}
                                                    />
                                                </div>
                                                {columnSetup.schema === "centerpoint" && (
                                                    <div className="col-span-1 sm:col-span-1 mb-6 sm:mb-auto">
                                                        <Select
                                                            id="customerEnvironment"
                                                            name="customerEnvironment"
                                                            label="Environment"
                                                            required={true}
                                                            options={Object.keys(Constants.editorEnvironmentNames).map(env => ({ name: Constants.editorEnvironmentNames[env], value: env }))}
                                                            selectedValue={values.customerEnvironment}
                                                            onChange={(v, name) => {
                                                                setFieldValue(name, v);
                                                                setSearchRequest(prevState => ({
                                                                    ...prevState,
                                                                    customerEnvironment: v,
                                                                }));
                                                            }}
                                                            error={errors.customerEnvironment}
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <div className="flex justify-end mt-6">
                                                <button type="submit"
                                                    disabled={searchAction.isExecuting || isSubmitting}
                                                    className="w-48 justify-center inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-pink hover:bg-brand-pink-hover disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink"
                                                >
                                                    {
                                                        searchAction.isExecuting && (
                                                            <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                        )
                                                    }
                                                    Search
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                            <div className="relative mt-3 flex-1 flex flex-col h-full">
                                <main className="absolute inset-0 h-full overflow-x-hidden" style={{ "minHeight": "500px" }}>
                                    <DocumentTable />
                                </main>
                            </div>
                        </div>
                    </div>
                </ViewpointSearchContext.Provider>
            )}
        </>
    )
}