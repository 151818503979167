import { useContext, useState } from "react";
import { useSelector } from "react-redux"
import { useColumnSetup } from "../../../hooks/useColumnSetup";
import Pagination from "../../../components/Pagination";
import DocumentViewDialog from "./documentViewDialog";
import DocumentViewButton from "./documentViewButton";
import { useTableSort } from "../../../hooks/useTableSort";
import * as _ from 'lodash'
import { ViewpointSearchContext } from "../../../contexts";
import DateTimeParser from "../../../utils/DateTimeParser";
import { OidcRoutesContext } from "../../../contexts";

export default function DocumentTable() {
    const columnSetup = useSelector((state) => state.columnSetup.current);
    const { getViewedColumnDefinitions } = useColumnSetup();
    const columnDefinitions = getViewedColumnDefinitions(columnSetup);
    const { userProfile } = useContext(OidcRoutesContext);

    const { searchResult, searchRequest, pagination, isSearching, onTableSort, onPaginationChange } = useContext(ViewpointSearchContext);

    const sortFunction = (documentModels, column, sortDirection) => {
        return _.orderBy(documentModels, data => {
            return [
                data.documentColumns.find(documentColumn => documentColumn.viewpointColumn === column).value
            ];
        }, [sortDirection]);
    }

    const tableSort = useTableSort(searchResult?.documentModels, searchRequest?.sorter?.column, searchRequest?.sorter?.sortOrder, sortFunction);

    const [viewDialogTop, setViewDialogTop] = useState(0)
    const [viewDialogLeft, setViewDialogLeft] = useState(0)
    const [selectedDocumentModel, setSelectDocumentModel] = useState()

    const handleViewButtonClick = (top, left, documentModel) => {
        setViewDialogTop(top)
        setViewDialogLeft(left)
        setSelectDocumentModel(documentModel);
    }

    const handleViewPanelClose = () => {
        setViewDialogTop(0)
        setViewDialogLeft(0)
        setSelectDocumentModel(null);
    }

    const handleDocumentColumn = (documentColumn) => {
        if (documentColumn.viewpointColumn === "ArchiveDate" || documentColumn.viewpointColumn === "RetentionStartDate") {
            return DateTimeParser.convertUtcStringToLocaleDateTimeString(documentColumn.value);
        } else {
            return documentColumn.value;
        }
    }

    return (
        <>
            <div className="flex flex-col h-full ">
                {searchResult && (
                    <div className="text-sm font-medium text-brand-pink mt-3 pb-2">
                        {searchResult.count === 0
                            ?
                            `0 Documents ( ${searchResult.searchTimeInSeconds} seconds )`
                            :
                            `${searchResult.count} Documents, Page ${pagination.currentPage + 1} of ${pagination.totalPages} ( ${searchResult.searchTimeInSeconds} seconds )`}
                    </div>
                )}

                <div className="overflow-x-auto overflow-y-auto" >
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                {columnDefinitions && columnDefinitions.map((columnDefinition) => (
                                    <th
                                        key={columnDefinition.presentationLabel}
                                        scope="col"
                                        className="z-10 sticky top-0 bg-gray-50"
                                    >
                                        <button
                                            className="inline-flex items-center w-full px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider focus:outline-none"
                                            disabled={isSearching}
                                            onClick={() => {
                                                const newSortDirection = tableSort.orderBy(columnDefinition.viewpointColumn)
                                                onTableSort({
                                                    columnDefinitionId: columnDefinition.id,
                                                    column: columnDefinition.viewpointColumn,
                                                    sortOrder: newSortDirection,
                                                });
                                            }}
                                        >
                                            {columnDefinition.presentationLabel} {tableSort.getSortIcon(columnDefinition.viewpointColumn)}
                                        </button>
                                    </th>
                                ))}
                                <th scope="col" className="z-10 sticky top-0 right-0 bg-gray-50 px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <span className="sr-only">View</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {tableSort?.tableData?.map((documentModel) => (
                                <tr key={documentModel.id}>
                                    {documentModel.documentColumns && documentModel.documentColumns.map((documentColumn) => (
                                        <td key={documentColumn.viewpointColumn} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">
                                            {handleDocumentColumn(documentColumn)}
                                        </td>
                                    ))}
                                    <td className="sticky right-0 bg-white px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <DocumentViewButton onClick={handleViewButtonClick} documentModel={documentModel} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {searchResult && searchResult.count > 0 && (
                    <Pagination
                        pagination={pagination}
                        disabled={isSearching}
                        onPaginationChange={(e) => onPaginationChange(e)}
                    />
                )}
            </div>

            {
                !isSearching && selectedDocumentModel && (
                    <DocumentViewDialog documentModel={selectedDocumentModel} top={viewDialogTop} left={viewDialogLeft} onClose={handleViewPanelClose} />
                )
            }

        </>

    )
}