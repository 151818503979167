import { Dialog } from "@headlessui/react"
import { useSelector } from "react-redux"
import { useState } from "react";
import { Link } from "react-router-dom";

export default function DocumentViewDialog({ documentModel, top, left, onClose }) {
    const columnSetup = useSelector((state) => state.columnSetup.current);
    let [isOpen, setIsOpen] = useState(true)

    const divStyle = {
        position: 'absolute',
        top: `${top}px`,
        left: `${left}px`,
        width: '180px',
    }

    const handleClose = () => {
        setIsOpen(false);
        onClose();
    }

    return (
        <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-hidden z-50"
            open={isOpen}
            onClose={() => handleClose()}
        >
            <Dialog.Panel
                className="bg-white rounded border shadow px-3 py-3 mt-1 flex flex-col space-y-3"
                style={divStyle}
            >
                {documentModel && documentModel.contents && documentModel.contents.map((content) => (
                    <Link
                        key={content.id}
                        to={`/view/${columnSetup.schema}/${content.id}/${columnSetup.viewpointConnectionId}`}
                        rel="noreferrer"
                        target="_blank"
                        className="text-sm hover:text-brand-pink focus:outline-none"
                        onClick={() => handleClose()}
                    >
                        {content.comment}
                    </Link>
                ))}
            </Dialog.Panel>
        </Dialog>
    )
}