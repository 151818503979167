import { useRef, useContext } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { ViewpointSearchContext } from "../../../contexts";

export default function DocumentViewButton({ documentModel, onClick }) {
    const referenceElement = useRef();
    const viewpointSearchContext = useContext(ViewpointSearchContext);

    const handleClick = () => {
        const rect = referenceElement.current.getBoundingClientRect();
        onClick(rect.bottom, rect.right - 180, documentModel);
    }

    return (
        <button
            ref={referenceElement}
            className="inline-flex items-center px-4 py-2 text-sm hover:text-brand-pink focus:outline-none focus:text-brand-pink disabled:cursor-not-allowed disabled:text-gray-500"
            disabled={viewpointSearchContext.isSearching}
            onClick={() => handleClick()}
        >
            View
            <ChevronDownIcon className="ml-2 -mr-1 h-4 w-4"></ChevronDownIcon>
        </button>
    )
}