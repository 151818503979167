import { useContext } from "react";
import { OidcRoutesContext } from "../contexts";
import * as Constants from '../utils/constants'

export const useFeature = () => {
    const oidcRoutesContext = useContext(OidcRoutesContext);

    return {
        hasFeature: (featureCode) => {
            const product = oidcRoutesContext.userProfile.customerUserAccess?.productFeatureAccessSelections?.find(x => x.productId === Constants.adminProductId);

            if (!featureCode)
                return true;

            const ret = product?.featureSelections?.find(x => x.featureCode === featureCode)?.hasAccess;
            return !!ret;
        }
    }
}