import { useClassNames } from "@metaforcelabs/metaforce-core";

/**
 * @param {*} SidebarTextbox 
 */
export const FormCheckbox = ({
  name,
  label,
  value,
  disabled,
  onChange,
  placeholder = null,
  error
}) => {
  const { classNames } = useClassNames();

  return (
    <>
      <div className="relative flex items-start py-2">
        <div className="min-w-0 flex-1 text-sm">
          <label className="font-medium text-gray-700 select-none">
            {label}
          </label>
        </div>
        <div className="ml-3 flex items-center h-5">
          <input
            type="checkbox"
            name={name}
            checked={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            className={classNames(
              "sm:col-span-3 block focus:ring-brand-pink focus:border-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded disabled:opacity-50 disabled:cursor-not-allowed mt-3",
              error
                ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300"
                : ""
            )}
          />
          {
            error &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {error}
            </p>
          }
        </div>
      </div>

    </>
  );
}