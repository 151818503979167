import { useClassNames } from "@metaforcelabs/metaforce-core";
import { format } from 'date-fns';
import { useState } from "react";

export const FormDate = ({
  name,
  label,
  value,
  disabled,
  onChange,
  placeholder = null,
  required = false,
  error,
  touched,
}) => {
  const { classNames } = useClassNames();

  const dateFormat = "yyyy-MM-dd";
  const initialDateText = format(value, dateFormat);
  const [dateValue, setDateValue] = useState(initialDateText);

  const handleChange = (e) => {
    setDateValue(e.target.value);
    onChange(e);
  }

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        {required === true && <span className="text-red-400"> *</span>}
      </label>
      <div className="mt-2">
        <input
          type="date"
          required
          name={name}
          defaultValue={dateValue}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e) => handleChange(e)}
          className={classNames(
            "block w-full shadow-sm sm:text-sm focus:ring-brand-pink focus:border-brand-pink border-gray-300 rounded-md disabled:opacity-50 disabled:cursor-not-allowed ",
            error && touched
              ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 "
              : ""
          )}
        />
        {
          error && touched &&
          <div className="mt-2 text-sm text-red-600">
            {error}
          </div>
        }
      </div>
    </div>
  );
}