import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/outline";
import { useState } from "react";

export const useTableSort = (data, propertyName, direction, sortFunction) => {
    const [sortProperty, setSortProperty] = useState(propertyName);
    const [sortDirection, setSortDirection] = useState(direction);

    return {
        tableData: data,
        orderBy: (propertyName) => {
            if (propertyName === sortProperty) {
                const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
                setSortDirection(newSortDirection);
                return newSortDirection;
            } else {
                setSortProperty(propertyName)
                setSortDirection('asc')
                return 'asc';
            }
        },
        getSortIcon: (propertyName) => {
            if (propertyName !== sortProperty) {
                return null;
            }
            return sortDirection === "asc"
                ? <ArrowSmUpIcon className="h-5 w-5 inline"></ArrowSmUpIcon>
                : <ArrowSmDownIcon className="h-5 w-5 inline"></ArrowSmDownIcon>
        },
    }
}