import { createSlice } from "@reduxjs/toolkit";

export const columnSetupSlice = createSlice({
    name: 'columnSetup',
    initialState: {
        current: null,
    },
    reducers: {
        setColumnSetup: (state, action) => {
            const { columnSetup } = action.payload;
            state.current = columnSetup;
            state.selectedPrivateConnectionId = columnSetup.viewpointConnectionId;
        }
    }
})

export const {
    setColumnSetup,
    getColumnDefinitions,
    getColumnDefinition
} = columnSetupSlice.actions

export default columnSetupSlice.reducer