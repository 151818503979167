import React, { useEffect, useRef, useState } from 'react';
import DayPicker from 'react-day-picker';
import DateTimeParser from '../../utils/DateTimeParser';
import styles from './DateField.module.scss';
import 'react-day-picker/lib/style.css';
import { useClassNames } from '@metaforcelabs/metaforce-core';
import DateFieldYearMonthForm from './components/DateFieldYearMonthForm';
import DateFieldNavbar from './components/DateFieldNavbar';

const DateField = (props) => {
  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear - 140, 0);
  const toMonth = new Date(currentYear, 0);
  const dayPickerWrapperRef = useRef(null);
  const dayPickerRef = useRef(null);
  const [isShowingDayPicker, setIsShowingDayPicker] = useState(false);
  const [disabledDays, setDisabledDays] = useState();
  const [selectedDay, setSelectedDay] = useState(props.value);
  const { classNames } = useClassNames();

  const handleSetDisbaledDays = () => {
    const result = [];

    if (props.minDate) {
      result.push({
        before: props.minDate
      });
    }

    if (props.maxDate) {
      result.push({
        after: props.maxDate
      });
    }

    return result;
  };

  const handleYearMonthChange = (month) => {
    setSelectedDay(month);
  };

  const handleOnDayClick = (date, { disabled }) => {
    if (disabled) return;

    setIsShowingDayPicker(false);
    setSelectedDay(date);

    if (props.onChange) {
      const handleChangeEvent = {
        target: {
          id: props.name,
          name: props.name,
          value: date
        }
      };

      props.onChange(date, handleChangeEvent);
    }
  };

  const handleClick = (e) => {
    if (props.disabled) {
      return;
    }
    if (dayPickerWrapperRef.current && dayPickerWrapperRef.current.contains(e.target)) {
      if (!(dayPickerRef.current && dayPickerRef.current.contains(e.target))) {
        setIsShowingDayPicker((prev) => !prev);
      }
    } else {
      setIsShowingDayPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClick);

    return () => {
      document.removeEventListener('mouseup', handleClick);
    };
  });

  useEffect(() => {
    if (props.value) {
      setSelectedDay(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    const disabledDaysResult = handleSetDisbaledDays();

    setDisabledDays(disabledDaysResult);
  }, [props.minDate, props.maxDate]);

  return (
    <>
      <div ref={dayPickerWrapperRef} className={styles.wrapper}>
        <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
          {props.label}
          {props.required === true && <span className="text-red-400"> *</span>}
        </label>
        <div className="mt-2">
          <input
            type="text"
            id={props.name}
            name={props.name}
            value={DateTimeParser.toLocaleDateString(props.value)}
            className={classNames(
              'shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-brand-pink focus:border-brand-pink',
              props.disabled && styles.disabled
            )}
            disabled={props.disabled}
            readOnly
          />

          <div className="text-red-500">{props.error && <div>{props.error}</div>}</div>
        </div>

        {isShowingDayPicker && !props.disabled && (
          <div ref={dayPickerRef} className={styles.dayPicker}>
            <DayPicker
              month={selectedDay}
              selectedDays={selectedDay}
              locale={props.locale || 'en'}
              disabledDays={disabledDays}
              captionElement={
                props.navbar
                  ? ({ date, localeUtils }) => (
                    <DateFieldYearMonthForm
                      fromMonth={fromMonth}
                      toMonth={toMonth}
                      date={date}
                      localeUtils={localeUtils}
                      onChange={handleYearMonthChange}
                    />
                  )
                  : undefined
              }
              navbarElement={props.navbar ? (props) => <DateFieldNavbar {...props} /> : undefined}
              onDayClick={handleOnDayClick}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DateField;
