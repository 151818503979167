
import { UserCircleIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useToastAction } from "@metaforcelabs/metaforce-core";
import { getColumnSetupHistory } from "../../../api/columnSetup";

export default function ColumnSetupHistorySidebar(updated) {
    const columnSetup = useSelector((state) => state.columnSetup.current);
    const [histories, setHistories] = useState();

    const loadAction = useToastAction();

    const loadData = async () => {
        loadAction.execute(async () => {
            const res = await getColumnSetupHistory(columnSetup?.id);
            setHistories(res);
        }, "Failed to load setting histories");
    }

    useEffect(() => {
        loadData()
    }, [updated]);

    return (loadAction.isExecuting
        ? (
            <div className="px-3 h-full w-full">
                <div className="flex h-full items-center justify-center">
                    <svg className="m-auto animate-spin -ml-1 mr-3 h-6 w-6 text-brand-pink" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            </div>
        )
        : (
            <div className="flow-root py-6 px-4 bg-white h-full">
                <ul role="list" className="-mb-8">
                    {histories && histories.map((history) => (
                        <li key={history.id}>
                            <div className="relative pb-8">
                                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                <div className="relative flex space-x-3">
                                    <div>
                                        <span className="h-8 w-8 rounded-full bg-brand-pink flex items-center justify-center">
                                            <UserCircleIcon className="h-5 w-5 text-white"></UserCircleIcon>
                                        </span>
                                    </div>
                                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                        <div>
                                            <p className="text-sm text-gray-500">{history.createdTime} - Changed by {history.userId}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        ))
}