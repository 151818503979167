import { BaseUrl, post,get } from './api'


export const searchDocument = async (filterModel) => {
  return await post(`api/DocumentSearch/search`, BaseUrl.Api, filterModel);
}

export const downloadDocument = async (schema, documentId, connectionId) => {
  if (connectionId) {
    return await get(`api/DocumentSearch/view/${schema}/${documentId}/${connectionId}`, BaseUrl.Api);
  } else {
    return await get(`api/DocumentSearch/view/${schema}/${documentId}`, BaseUrl.Api);
  }
}


